<template>
	<div class="movie-hall page">
		<van-nav-bar title="" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#e6b98a" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="movie-video">
			<video id="my-video" class="video-js vjs-default-skin" controls preload="auto" width="500px">
				<source :src="videoSrc" type="application/x-mpegURL" />
			</video>
		</div>
	</div>
</template>

<script>
// import { Toast } from 'vant';
import videojs from 'video.js';
import 'videojs-contrib-hls';
export default {
	data() {
		return {
			videoSrc: 'https://m3u8.pinduoduo-cn.app/20220716/sKSIjgjp/index.m3u8',
			player:null
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		getUrlParam() {
			let geturl = window.location.href;
			let getqyinfo = geturl.split('?')[1];
			let getqys = getqyinfo.split('&');
			let obj = {}; //创建空对象，接收截取的参数
			for (let i = 0; i < getqys.length; i++) {
				let item = getqys[i].split('=');
				let key = item[0];
				let value = item[1];
				obj[key] = value;
			}
			this.videoSrc = decodeURIComponent(obj.videourl);
			let _that = this;
			setTimeout(() => {
				_that.getVideo();
			}, 3000);
		},
		getVideo() {
			this.$nextTick(() => {
				//将此操作放在nextTick中  这样【获取到的就是我们赋值视频路径后的dom】
				this.player = videojs(
					'my-video',
					{
						bigPlayButton: false,
						textTrackDisplay: false,
						posterImage: true,
						errorDisplay: false,
						controlBar: true
					},
					function() {
						this.play();
					}
				);
			});
		}
	},
	created() {
		if (!localStorage.getItem('token')) {
			this.$router.push({ path: '/Login' });
		} else {
			this.getUrlParam();
		}
	},
	destroyed() {
		this.player.dispose();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #16151b;
	height: 100%;
}
.nav-bar {
	/* background: linear-gradient(
      90deg,#7e5678,#e6c3a1); */
	height: 100px;
	background-color: #16151b;
	position: fixed;
}
.van-nav-bar {
	line-height: 50px;
}

::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #f3c7a2;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.movie-video {
	width: 100%;
	height: 100%;
}
.video-js {
	width: 100%;
	height: 100%;
	font-size: 24px;
}
</style>
