<template>
  <div class="container page">
    <van-nav-bar :title="$t('填写转数快')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">{{ $t("请输入您的转数快信息") }}</div>
      <van-cell-group>
        <van-field v-model="zsk_name" :label="$t('姓名')" type="text" :placeholder="$t('请输入真实姓名')" />
        <van-field v-model="phone_number" :label="$t('联系电话')"  type="digit"  :placeholder="$t('请输入联系电话')" />
      </van-cell-group>
      <p>{{ $t("根据银联风控要求同卡24小时内验证次数不能超过4次，感谢配合") }}</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindZsk()">{{ $t("确认") }}</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :title="$t('标题')"
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
          :cancel-button-text="$t('取消')"
          :confirm-button-text="$t('确认')"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank:false,
      userInfo:{},
      zsk_name:"",
      phone_number:""
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindZsk(){
      if(this.userInfo.zsk_name){
        this.$toast(this.$t("已存在提款方式，请勿重复绑定"));
        return true;
      }
      if(this.phone_number === "" || this.phone_number === null || this.phone_number === undefined){
        this.$toast.fail(this.$t("请输入联系电话"));
        return false;
      }
      if(this.zsk_name === "" || this.zsk_name === null || this.zsk_name === undefined){
        this.$toast.fail(this.$t("请输入真实姓名"));
        return false;
      }
      this.$http({
        method: 'post',
        data:{zsk_name:this.zsk_name,phone_number:this.phone_number},
        url: 'user_set_zsk'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
